import React, { useState, useEffect, useMemo } from 'react'
import styles from './Timer.module.css'
import { useAuthenticatedAPI } from '../../hooks/useAuthenticatedAPI'

export default function Timer({ period, setPeriod, daopage }) {
  const { api } = useAuthenticatedAPI()

  useEffect(() => {
    const getCurrentPeriod = async () => {
      const res = await api.getCurrentVotingPeriod()
      // console.log('getCurrentVotingPeriod', await api.getCurrentVotingPeriod())

      setPeriod((period) => ({ ...period, currentPeriod: res.name }))

      const convertTime = (ms) => {
        const days = Math.floor(ms / (24 * 60 * 60 * 1000))
        const daysms = ms % (24 * 60 * 60 * 1000)
        const hours = Math.floor(daysms / (60 * 60 * 1000))
        const hoursms = ms % (60 * 60 * 1000)
        const minutes = Math.floor(hoursms / (60 * 1000))
        const minutesms = ms % (60 * 1000)
        const sec = Math.floor(minutesms / 1000)
        return setPeriod((period) => ({
          ...period,
          timeLeft:
            days + ' days ' + hours + ' h ' + minutes + ' m ' + sec + ' s',
        }))
      }
      convertTime(res.timeLeft)
    }

    const interval = setInterval(() => getCurrentPeriod(), 1000)

    return () => clearInterval(interval)
  }, [api])

  return (
    <div
      className={
        daopage ? `${styles.periodDiv} ${styles.daopage}` : styles.periodDiv
      }
    >
      <h4>Current Period: {period.currentPeriod}</h4>
      <h4>Time left: {period.timeLeft}</h4>
    </div>
  )
}

// const SECOND = 1000
// const MINUTE = SECOND * 60
// const HOUR = MINUTE * 60
// const DAY = HOUR * 24

// export default function Timer() {
//   const deadline = '2022-11-01T23:59:59'
//   const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline])
//   const [time, setTime] = useState(parsedDeadline - Date.now())

//   useEffect(() => {
//     const interval = setInterval(
//       () => setTime(parsedDeadline - Date.now()),
//       1000,
//     )

//     return () => clearInterval(interval)
//   }, [])

//   return (
//     <div className={styles.periodDiv}>
//       {Object.entries({
//         Days: time / DAY,
//         Hours: (time / HOUR) % 24,
//         Minutes: (time / MINUTE) % 60,
//         Seconds: (time / SECOND) % 60,
//       }).map(([label, value]) => (
//         <div key={label} className={styles['col-4']}>
//           <p>{`${Math.floor(value)}`.padStart(2, '0')}</p>
//           <span className={styles.text}>{label}</span>
//         </div>
//       ))}
//     </div>
//   )
// }
