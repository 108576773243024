import React from "react";
import styles from "./Footer.module.css";
import { Mailto } from "../hooks/Mailto";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div>
        <a href="/">
          <img
            src={require("../../assets/website caller inu/pc/1. Caller Inu logo_smalllll.png")}
            alt="logo"
            className={styles.brandLogo}
          />
        </a>
      </div>
      <div className={styles.socialMedia}>
        <a href="https://callerinu.gitbook.io/">
          <img
            src={require("../../assets/website caller inu/pc/1. socials_gidbook_.png")}
            alt="gidbook"
          />
        </a>
        <Mailto
          email="callerinu@gmail.com"
          subject="Hello"
          body="Hello world!"
        />
        <a href="https://twitter.com/CallerInu">
          <img
            src={require("../../assets/website caller inu/pc/1. socials_twitter.png")}
            alt="twitter"
          />
        </a>
        <a href="https://www.youtube.com/watch?v=3-bTKVJhM9o&ab_channel=CallerInuOfficial">
          <img
            src={require("../../assets/website caller inu/pc/1. socials_youtube.png")}
            alt="youtube"
          />
        </a>
        <a href="https://t.me/CallerInu">
          <img
            src={require("../../assets/website caller inu/pc/1. socials_telegram.png")}
            alt="telegram"
          />
        </a>
      </div>
    </footer>
  );
}
