import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import useWallet from '../../hooks/useWallet'

export default function ConnectWalletButton() {
  const { currentAddress, connectWallet } = useWallet()

  return (
    <button onClick={() => connectWallet('metamask')}>
      {currentAddress
        ? currentAddress.slice(0, 6) + '...' + currentAddress.slice(-3)
        : 'Connect Wallet'}
    </button>
  )
}
