import React, { useState, useEffect } from 'react'
import styles from './DaoPage.module.css'
import { Form, Button } from 'react-bootstrap'
import TopNavbar from '../TopNavbar/TopNavbar'
import { useAuthenticatedAPI } from '../../hooks/useAuthenticatedAPI'
import useWallet from '../../hooks/useWallet'
import Timer from '../Timer/Timer'

export default function DaoPage() {
  const [pageClicked, setPageClicked] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [period, setPeriod] = useState({ currentPeriod: '', timeLeft: '' })
  const [topProposals, setTopProposals] = useState([])

  const { api } = useAuthenticatedAPI()
  const { user } = useWallet()

  async function submitProposal(e) {
    e.preventDefault()
    await api.submitProposal({ title, description })

    // other example api calls:
    // console.log(await api.castVote({ proposalId: 1, vote: 'for' }));
    // console.log(await api.getProposals())
  }

  useEffect(() => {
    if (period.currentPeriod === 'showing') {
      const showing = async () => {
        const res = await api.getTopProposals()
        setTopProposals(res)
      }
      showing()
    }
  }, [api, period])

  return (
    <section
      className={
        pageClicked
          ? `${styles.roomSection} ${styles.clicked}`
          : styles.roomSection
      }
    >
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vw',
          zIndex: '50',
        }}
        className={styles.onClickDiv}
        onClick={() => setPageClicked(!pageClicked)}
      ></div>
      <TopNavbar />
      <div className={styles.tvDiv}>
        {pageClicked ? (
          <Timer period={period} setPeriod={setPeriod} daopage />
        ) : (
          <></>
        )}
        <img src={require('../../assets/callerdao/dao_tv.png')} alt="tv" />

        {period.currentPeriod === 'proposing' ? (
          user.is_holder ? (
            <Form>
              <Form.Group
                className={styles['mb-3']}
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                />
              </Form.Group>
              <Form.Group
                className={styles['mb-3']}
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Button onClick={submitProposal} variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          ) : (
            <></>
          )
        ) : period.currentPeriod === 'voting' ? (
          <></>
        ) : period.currentPeriod === 'showing' ? (
          <ul>
            <h2>Top Proposals</h2>
            {topProposals.map((el) => (
              <li key={el.id}>{el.title}</li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </section>
  )
}
