import React from "react";
import TopNavbar from "../TopNavbar/TopNavbar";
import Footer from "../Footer/Footer";
import styles from "./LandingPage.module.css";
import { TiPlusOutline } from "react-icons/ti";
import ReactPlayer from "react-player/file";
import Nft from "../Nft/Nft";
import Auction from "../Auction/Auction";
import { Link } from "react-router-dom";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export default function LandingPage() {
  // LEAVE ONE EXAMPLE IN CASE I NEED IT LATER
  // const introductionSection = useRef(null)
  // const scrollDown = (ref) => {
  //   window.scrollTo({
  //     top: ref?.current?.offsetTop - 150,
  //     behavior: 'smooth',
  //   })
  // }

  return (
    <>
      <TopNavbar
      // scrollDown={scrollDown}
      // utilitiesSection={utilitiesSection}
      />
      <div className={styles.content}>
        <section
          className={styles.introductionContainer}
          id="introductionSection"
        >
          <div className={styles.introduction}>
            {/* <h2>INTRODUCTION</h2>
          <p>
            caller inu is a decentralized autonomous organisation-based token,
            which will rely on the decisions on its conclave, accompanied by the
            community position on topics, which will be raised by a voting
            system implemented on our wevsite-inspired by the numerous dedicated
            people with call channels, who are spending time daily to search and
            further give exposure to prejects.
          </p>
          <p>
            caller inu's mission is to make the process more accessible than it
            is at this current stage. with oversaturated platforms, such as -
            telegram, twitter, discord etc.
          </p>
          <p>
            caller inu will compress the amount of information based on one's
            preference.
          </p> */}
            <img
              src={require("../../assets/website caller inu/pc/2. intro_all.png")}
              alt="introduction"
              style={{ width: "100%" }}
              className={styles.introImage}
            />
            <img
              src={require("../../assets/website caller inu/mobile/2.-intro_all.png")}
              alt="introduction"
              style={{ width: "100%" }}
              className={styles.introImageMobile}
            />
          </div>
          <Auction />
          <div className={styles.popArt}>
            {/* <img
            src={'https://via.placeholder.com/150'}
            // src={require('../../assets/Caller/pop_art_1.png')}
            alt="pop_art_1"
          />
          <img
            src={'https://via.placeholder.com/150'}
            // src={require('../../assets/Caller/pop_art_2.png')}
            alt="pop_art_2"
          />
          <img
            src={'https://via.placeholder.com/150'}
            // src={require('../../assets/Caller/pop_art_3.png')}
            alt="pop_art_3"
          /> */}
            <img
              src={require("../../assets/website caller inu/pc/3. girls_all.png")}
              alt="girls"
              style={{ width: "100%" }}
            />
          </div>
          {/* <div></div> */}
        </section>
        <section
          // ref={utilitiesSection}
          id="utilitiesSection"
        >
          {/* <h2>UTILITIES</h2>
        <div className={styles.utilityList}>
          <div>
            <h3>caller bot</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Excepturi rem aut dolore! Similique, laudantium possimus
              consectetur accusantium repudiandae illum dicta aspernatur harum
              aperiam quo est quibusdam! Accusantium eius vero voluptates?
            </p>
          </div>
        </div> */}
          <div className={styles.utilities}>
            <img
              src={require("../../assets/website caller inu/pc/bot-button-shadow.png")}
              className={styles.botButtonShadowHover}
              onClick={() => window.open("https://t.me/CallerInuBot", "_blank")}
            />
            <div className={styles.utilitiesBackground}>
              <img
                src={require("../../assets/website caller inu/pc/bot-button-shadow.png")}
                className={styles.botButtonShadow}
              />
              <img
                src={require("../../assets/website caller inu/pc/4.-utilities_bot-button-no-shadow.png")}
                className={styles.botButton}
              />
              <img
                src={require("../../assets/website caller inu/pc/desktop-utilities-bg.png")}
                className={styles.utilitiesImage}
              />
            </div>
          </div>
          <div className={styles.utilitiesMobile}>
            <img
              src={require("../../assets/website caller inu/pc/bot-button-shadow.png")}
              className={styles.botButtonShadowHover}
              onClick={() => window.open("https://t.me/CallerInuBot", "_blank")}
            />
            <div className={styles.utilitiesBackground}>
              <img
                src={require("../../assets/website caller inu/pc/bot-button-shadow.png")}
                className={styles.botButtonShadow}
              />
              <a href="https://t.me/CallerInuBot">
                <img
                  src={require("../../assets/website caller inu/pc/4.-utilities_bot-button-no-shadow.png")}
                  className={styles.botButton}
                />
              </a>
              <img
                src={require("../../assets/website caller inu/mobile/mobile-utilities-bg.png")}
                alt="utilities"
                style={{ width: "100%" }}
                className={styles.utilitiesImage}
              />
            </div>
          </div>
        </section>
        <section className={styles.taxesAndToken} id="tokenomicsSection">
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/pc/5. tax&token_title.png")}
              alt="tax&token"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", width: "100%", gap: "5%" }}>
            <div>
              <img
                src={require("../../assets/website caller inu/pc/5. tax&token_pie-chart.png")}
                alt="utilities"
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <img
                src={require("../../assets/website caller inu/pc/5. tax&token_1.png")}
                alt="1%"
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <img
                src={require("../../assets/website caller inu/pc/5. tax&token_2.png")}
                alt="utilities"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </section>
        <section
          className={styles.taxesAndTokenMobile}
          id="tokenomicsSectionMobile"
        >
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/mobile/5.-tax&token_title.png")}
              alt="tax&token"
              style={{ width: "100%" }}
            />
          </div>
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/mobile/5.-tax&token_pie-chart.png")}
              alt="utilities"
              style={{ width: "100%" }}
            />
          </div>
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/mobile/5.-tax&token_.png")}
              alt="1%&2%"
              style={{ width: "100%" }}
            />
          </div>
        </section>
        <section id="artworkSection">
          <Nft />
        </section>
        <section className={styles.roadmap}>
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/pc/6. roadmap_title.png")}
              alt="roadmap"
            />
          </div>
          <div>
            <img
              src={require("../../assets/roadmap-graph.png")}
              alt="roadmap"
              style={{ width: "100%" }}
            />
          </div>
        </section>
        <section className={styles.roadmapMobile}>
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/mobile/6.-roadmap_titel.png")}
              alt="roadmap"
            />
          </div>
          <div>
            <img
              src={require("../../assets/roadmap-graph-mobile.png")}
              alt="roadmap"
              style={{ width: "100%" }}
            />
          </div>
        </section>
        <section className={styles.gallery} id="gallerySection">
          <div className={styles.galleryLeft}>
            <div className={styles.galleryDescription}>
              {/* <h2>GALLERY</h2>

            <b>an intereactive virtual reality gallery,</b>
            <p>
              with its walls filled with ads, posters, promotional slogans, and
              whatnot.
            </p>
            <br />
            <b>nothing like you've ever seen before!</b>
            <p>
              your nonconventional place for launching a marketing campaign.
            </p> */}
              <img
                src={require("../../assets/website caller inu/pc/7. gallery_all.png")}
                alt="lips"
              />
            </div>
            <div>
              {/* <img
              src={require('../../assets/callerinu/side art_mouth convo.png')}
              alt="lips"
            />
            <img
              src={require('../../assets/callerinu/side art_mouth convo bubble.png')}
              alt="open the door"
            />
            <img
              src={require('../../assets/callerinu/side art_phone convo.png')}
              alt="telephone"
            />
            <img
              src={require('../../assets/callerinu/side art_phone convo bubble.png')}
              alt="chill felicia"
            /> */}
              <img
                src={require("../../assets/website caller inu/pc/7. gallery_comic_all.png")}
                alt="chill felicia"
              />
            </div>
          </div>

          <div className={styles.galleryRight}>
            {/* <img
            src={require('../../assets/Caller/gallery.png')}
            alt="gallery image"
          /> */}
            <ReactPlayer
              url={require("../../assets/website caller inu/pc/7. Flickering Light Gallery.m4v")}
              playing={true}
              loop={true}
              muted={true}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </section>
        <section className={styles.galleryMobile} id="gallerySectionMobile">
          <div className={styles.galleryDescription}>
            <img
              src={require("../../assets/website caller inu/mobile/7.-gallery_all.png")}
              alt="header and description"
            />
            <div>
              <img
                src={require("../../assets/website caller inu/mobile/7.-mouth_all.png")}
                alt="lips"
                className={styles.lips}
              />
            </div>
          </div>
          <div>
            <ReactPlayer
              url={require("../../assets/website caller inu/pc/7. Flickering Light Gallery.m4v")}
              playing={true}
              loop={true}
              muted={true}
              width={"100%"}
              height={"100%"}
            />
            <div className={styles.phoneDiv}>
              <img
                src={require("../../assets/website caller inu/mobile/7.-phone_all.png")}
                alt="chill felicia"
                className={styles.phone}
              />
            </div>
          </div>
        </section>
        <section className={styles.team} id="teamSection">
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/pc/8. team_title.png")}
              alt="the team"
            />
          </div>
          <div
            className={styles.teamMember}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                src={require("../../assets/website caller inu/pc/8. team_mike.png")}
                alt="Mike"
              />
            </div>
            <div>
              <img
                src={require("../../assets/website caller inu/pc/8. team_grun.png")}
                alt="Grun"
              />
            </div>
            <div>
              <img
                src={require("../../assets/website caller inu/pc/8. team_lotte.png")}
                alt="Lotte"
              />
            </div>
          </div>
          <div
            className={styles.teamMember}
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5%",
            }}
          >
            <div>
              <img
                src={require("../../assets/website caller inu/pc/8. team_martin.png")}
                alt="Martin"
                className={styles.martin}
              />
            </div>

            <div>
              <img
                src={require("../../assets/website caller inu/pc/8. team_rick.png")}
                alt="Rick"
              />
            </div>
          </div>
        </section>
        <section className={styles.supportedBy} id="supportedBySection">
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/pc/9. supported-by.png")}
              alt="supported by"
            />
          </div>
          <div className={styles.supportedByLogos}>
            <a
              target="_blank"
              href="https://github.com/cyberscope-io/audits/blob/main/CallerInu/audit.pdf"
            >
              <img
                src={require("../../assets/logos/cyberscope.png")}
                alt="Cyberscope"
                style={{ height: 70 }}
              />
            </a>
            <a
              target="_blank"
              href="https://bscscan.com/address/0xfc4313d3e90e15b9b1407c7fb7c9b7f184433666"
            >
              <img
                src={require("../../assets/logos/binance.png")}
                alt="Binance chain"
                style={{ height: 210 }}
              />
            </a>
            <a
              target="_blank"
              href="https://www.pinksale.finance/launchpad/0x5A2e6709030b385E5D45e4bBdcc2de6B86c4EDbA?chain=BSC"
            >
              <img
                src={require("../../assets/logos/pinksale.png")}
                alt="PinkSale"
                style={{ height: 70 }}
              />
            </a>
            <a
              target="_blank"
              href="https://pancakeswap.finance/swap?outputCurrency=0xFC4313D3e90e15B9B1407c7Fb7c9B7f184433666"
            >
              <img
                src={require("../../assets/logos/pancake.png")}
                alt="PancakeSwap"
                style={{ height: 70 }}
              />
            </a>
            <img
              src={require("../../assets/logos/dexview.png")}
              alt="dexview"
              style={{ filter: "invert(1)", height: 70 }}
            />
            <img
              src={require("../../assets/logos/dexscreener.png")}
              alt="dexscreener"
              style={{ height: 70 }}
            />
            <a
              target="_blank"
              href="https://www.dextools.io/app/bnb/pair-explorer/0xe27fee528254ba9d1fd85ace8d447bf0a7347383"
              style={{ zIndex: "100" }}
            >
              <img
                src={require("../../assets/logos/dextools.png")}
                alt="dextools"
                style={{ height: 70 }}
              />
            </a>
          </div>
        </section>
        <section className={styles.contactUs}>
          <div className={styles.header}>
            <img
              src={require("../../assets/website caller inu/pc/10. contact-us.png")}
              alt="the team"
            />
          </div>
          <div>
            <img
              src={require("../../assets/website caller inu/pc/10. contact-us_info-all.png")}
              alt="telephone image"
              className={styles.phoneImage}
            />
          </div>
        </section>
        <section className={styles.contactUsMobile}>
          <img
            src={require("../../assets/website caller inu/mobile/10.-contact-us_all.png")}
            alt="telephone image"
          />
        </section>
      </div>
      <Footer />
    </>
  );
}
