import React from 'react'
import styles from './Nft.module.css'

import { useState } from 'react';

export default function Nft() {
    const [compareWidth, setCompareWidth] = useState(50);
    return (
        <>
            <div className={styles.title}>
                <img className={styles.titleImage} src={require('../../assets/nft/title.png')} />
            </div>
            <div className={styles.imagesWrapper}>
                <div className={styles.beforeImage}>
                    <img className={styles.beforeImageBackground} src={require('../../assets/nft/background.png')} alt="Before"/>
                    <img className={styles.beforeImageNft} src={require('../../assets/nft/nft.jpeg')} alt="Nft"/>
                </div>
               

                <div className={styles.compareOverlay} style={{ width: `${compareWidth}%` }}>
                    <video
                        className={styles.afterImage}
                        alt="After image"
                        src={require('../../assets/nft/nft-video.mp4')} autoPlay playsInline muted loop></video>
                </div>
                <input
                    className={styles.compareRange}
                    type="range"
                    min="0"
                    max="100"
                    step="step"
                    onChange={e => setCompareWidth(e.target.value)}
                    tabIndex="-1"
                />
                <div className={styles.handleWrap} style={{ left: `${compareWidth}%` }}>
                    <div className={styles.handle}>
                        <img className={styles.slider} src={require('../../assets/nft/slide.png')} />
                    </div>
                    <span className={styles.handleLine}></span>
                </div>
            </div>
        </>
    )
}