import React from 'react'
import styles from './StartupAnimation.module.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { BsFacebook, BsInstagram, BsSkype } from 'react-icons/bs'
import LandingPage from '../LandingPage/LandingPage'
import { useState } from 'react'
import { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

export default function StartupAnimation() {
  const [animationState, setAnimationState] = useState(0)
  const isMobile = useMediaQuery(
    {
      query: `(max-width: 800px)`,
    },
    undefined,
    (isMobile) => {
      if (isMobile) {
        setAnimationState(3)
      }
    },
  )

  // 0: ringing video
  // 1: waiting for ringing to end
  // 2: picking up
  // 3: landing page

  // hides the elements instead of not rendering them at all to preload them
  const toggle = (cond, el, transition) => (
    <div
      style={{
        opacity: cond ? '1' : '0',
        overflow: 'hidden',
        display: cond ? 'block' : 'none',
        transition: transition ? 'opacity 0.4s ease 0s' : 'initial'
      }}
    >
      {el}
    </div>
  )
  const pickupVideo = useRef(null)

  return (
    <>
      {isMobile ? (
        <LandingPage></LandingPage>
      ) : (
        <div
          style={
            animationState == 3
              ? undefined
              : { height: '100vh', overflow: 'hidden' }
          }
        >
          {toggle(
            animationState == 0 || animationState == 1,
            <video
              style={{ zIndex: 100 }}
              className={styles.fullScreenAnimation}
              src={require('../../assets/website caller inu/pc/1. Ringing Phone.m4v')}
              autoPlay
              muted
              playsInline
              onClick={(event) => {
                event.target.playbackRate = 2.0
                pickupVideo.current.play()
                pickupVideo.current.pause()
                setAnimationState(1)
              }}
              onEnded={(event) => {
                if (animationState == 1) {
                  setAnimationState(2)
                  pickupVideo.current.play()
                } else {
                  event.target.play()
                }
              }}
            />,
          )}
          {toggle(
            animationState == 1 || animationState == 2,
            <video
              ref={pickupVideo}
              style={{ zIndex: 90 }}
              className={styles.fullScreenAnimation}
              src={require('../../assets/website caller inu/pc/1. Pick Up Phone.m4v')}
              muted
              playsInline
              preload="auto"
              onEnded={() => setAnimationState(3)}
            />,
            true,
          )}
          {toggle(animationState == 3, <LandingPage></LandingPage>)}
        </div>
      )}
    </>
  )
}
