import { ethers } from 'ethers';
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { v4 as uuidv4 } from 'uuid';

import { API } from './API';
import { config } from './config';

const api = new API();

const parseSig = (bytes) => {
    bytes = bytes.substr(2);
    const r = '0x' + bytes.slice(0, 64);
    const s = '0x' + bytes.slice(64, 128);
    const v = parseInt('0x' + bytes.slice(128, 130), 16);
    return { v, r, s };
};

/* TODO: move this to the error module */
class UserError extends Error {
    constructor(message) {
        super(message);
        this.type = 'UserError';
    }
}

const getAuthSignature = async ({ connector, currentAddress, onlyExisting }) => {
    const localStorageKeyForAccount = `v2:signature:${currentAddress}`;
    const storedSignature = localStorage.getItem(localStorageKeyForAccount);
    const storageSignatureDataKeyForAccount = `v2:signatureData:${currentAddress}`;
    const storedSignatureData = localStorage.getItem(storageSignatureDataKeyForAccount); 

    let signature = storedSignature;
    let signatureData = storedSignatureData;

    if (onlyExisting) {
        return { signature, signatureData };
    }

    if (!signature || !signatureData) {
        // signatureData = `
        // Welcome to Eternal Digital Assets!
        
        // Click to sign in and accept the Eternal Digital Assets Terms of Service: https://eternaldigitalassets.io/terms-of-use
        
        // This request will not trigger a blockchain transaction or cost any gas fees.
        
        // Your authentication status will reset after 24 hours.
        
        // Wallet address:
        // ${accounts[0]}
        
        // Nonce:
        // ${Math.floor(Math.random() * 1000000000000000000)}
        // `;

        /* TODO verify this data on the server, currently it is useless */
        signatureData = `Nonce: ${Math.floor(Math.random() * 1000000000000000000)}`;

        signature = await connector.personalSign(signatureData);
        localStorage.setItem(localStorageKeyForAccount, signature);
        localStorage.setItem(storageSignatureDataKeyForAccount, signatureData);
    }
    
    return { signature, signatureData };
}

const connectors = {
    'metamask': {
        tryExistingConnection: async () => {
            if (!window.ethereum) {
                return null;
            }

            const accounts = await window.ethereum.request({ method: 'eth_accounts' });

            if (window.ethereum.chainId !== config.networkParams.chainId) {
                return null;
            }
            
            return accounts[0];
        },
        connect: async () => {
            if (!window.ethereum) {
                throw new UserError('Metamask not installed. Please install the browser extension or use the mobile app.');
            }
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                return accounts[0];
            } catch (err) {
                if (err.code === 4001) {
                    throw new UserError('Metamask connection rejected by user.');
                }
                throw err;
            }
        },
        verifyChainId: async () => {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId != config.networkParams.chainId) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [
                            {
                                chainId: config.networkParams.chainId,
                            },
                        ],
                    });
                } catch (err) {
                    if (err.code === 4902 || err.code === -32603) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [config.networkParams],
                        });
                    } else if (err.code == 4001) {
                        throw new UserError('User rejected switching to the correct network.');
                    } else if (err.code == -32002) {
                        throw new UserError('Please switch to the correct network in Metamask.');
                    } else {
                        throw err;
                    }
                }
            }
        
            return true;
        },
        getEthersProvider: async () => {
            return new ethers.providers.Web3Provider(window.ethereum).getSigner();
        },
        personalSign: async (message) => {
            try {
                return await window.ethereum.request({
                    method: "personal_sign",
                    from: window.ethereum.selectedAddress,
                    params: [window.ethereum.selectedAddress, message],
                });
            } catch (err) {
                if (err.code === 4001) {
                    throw new UserError('User rejected signature request.');
                }
                throw err;
            }
        }
    }
}

const useWallet = create(
    immer((set, get) => {

        const addWalletOp = async (op) => {
            const uuid = uuidv4();
            set((state) => {
                state.walletOperations[uuid] = op;
            });
            return uuid;
        };

        const handleGenericWalletOp = async (executor, description) => {
            const uuid = await addWalletOp({
                type: 'generic',
                description,
                status: 'pending',
            });

            try {
                const result = await executor();

                set((state) => {
                    // state.walletOperations[uuid].status = 'completed';
                    delete state.walletOperations[uuid];
                });

                return result;
            } catch (err) {
                set((state) => {
                    state.walletOperations[uuid].status = 'error';
                    state.walletOperations[uuid].error = err;
                });
                throw err;
            }
        };


        return {
            currentAddress: null,
            explorerUrl: config.explorerUrl,
            user: {},
            walletOperations: {},
    
            connectWallet: async (connectorId) => {
                if (!connectorId) {
                    /* modal will trigger and will call connectWallet again */
                    let resolveFn;
                    const p = new Promise((resolve, reject) => {
                        resolveFn = resolve;
                    });
                    set((state) => {
                        state.choosingWallet = true;
                        state.resolveFn = resolveFn;
                    });
                    return p;
                } else {
                    set((state) => {
                        state.choosingWallet = false;
                    });   
                }

                if (get().connectingInProgress) {
                    return;
                }

                try {
                    set((state) => {
                        state.connectingInProgress = true;
                    });
    
                    const connector = connectors[connectorId];
    
                    const selectedAddress = await handleGenericWalletOp(() => connector.connect(), 'Choose an account');
                    const { signature, signatureData } = await handleGenericWalletOp(() => getAuthSignature({ connector, currentAddress: selectedAddress }), 'Sign message to verify your identity');
                    await handleGenericWalletOp(() => connector.verifyChainId(), 'Verify chain ID');
        
                    /* TODO: handle already pending */
                    /* TODO: handle other errors */
                    await get()._updateAccount({ selectedAddress, signature, signatureData, connectorId });
                    if (get().resolveFn) {
                        get().resolveFn();
                    }
                } finally {    
                    set((state) => {
                        state.connectingInProgress = false;
                    });
                }
            },
            abortChoosingWallet: async () => {
                set((state) => {
                    state.choosingWallet = false;
                });
            },
            disconnectWallet: async () => {
                await get()._updateAccount({ selectedAddress: null, connectorId: null });    
            },
            sendContractTransaction: async (contract, method, args, description) => {
                const uuid = await addWalletOp({
                    type: 'transaction',
                    status: 'pending',
                    description
                });
    
                let tx
                try {
                    tx = await contract[method](...args);
                } catch (err) {
                    /* TODO: make this cleaner */
                    if (err.code === 4001) {
                        set((state) => {
                            state.walletOperations[uuid].status = 'error';
                            state.walletOperations[uuid].error = new UserError('User rejected the transaction.');
                        });
                        throw new UserError('User rejected the transaction.');
                    }
                    throw err;
                }
    
                set((state) => {
                    state.walletOperations[uuid].status = 'sent';
                });
    
                await tx.wait();
                /* TODO handle transaction failing */

                set((state) => {
                    // state.walletOperations[uuid].status = 'completed';
                    delete state.walletOperations[uuid];
                });
            },
            dismissWalletOperation: async (uuid) => {
                set((state) => {
                    delete state.walletOperations[uuid];
                });
            },
            getContract: async (contractName, address) => {
                if (!get().currentAddress) {
                    await get().connectWallet();
                }
    
                const provider = await connectors[get().connectorId].getEthersProvider();
    
                const contract = new ethers.Contract(address || config.contractAddresses[contractName], config.contractABIs[contractName], provider);
                
                return contract;
            },
            tryExistingConnection: async () => {
                for (const connectorId in connectors) {
                    const connector = connectors[connectorId];
                    const selectedAddress = await connector.tryExistingConnection();
                    if (!selectedAddress) {
                        return;
                    }
                    const { signature, signatureData } = await getAuthSignature({ connector, currentAddress: selectedAddress, onlyExisting: true });
                    if (!signature && !signatureData) {
                        return;
                    }
                    get()._updateAccount({ selectedAddress, signature, signatureData, connectorId });
                    return;
                }
            },
            _updateAccount: async ({ selectedAddress, signature, signatureData, connectorId }) => {
                const currentAddress = selectedAddress.toLowerCase();
                const user = await api.getWalletInfo({ currentAddress });
    
                set((state) => {
                    state.currentAddress = currentAddress;
                    state.signature = signature;
                    state.signatureData = signatureData;
                    state.user = user;
                    state.connectorId = connectorId;
                })
            },
        };
    })
);

(async () => {
    await useWallet.getState().tryExistingConnection();

    // todo maybe move this to the connector code, but think about how to best not couple it with the state update
    window.ethereum.on('accountsChanged', (accounts) => {

        useWallet.getState().connectWallet('metamask');
        // useWallet.getState()._updateAccount({ selectedAddress: accounts[0], connectorId: 'metamask' });
    });

    //TODO THINK ABOUT THIS VERY HARD!
    // window.ethereum.on('chainChanged')
})();

export default useWallet;