export const config = {
  networkName: "Polygon Mainnet",
  explorerUrl: "https://polygonscan.com/tx/",
  openSeaUrl: "https://opensea.io/account",
  networkParams: {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Polygon Mainnet",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mainnet.matic.network"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  contractAddresses: {
    Auction: "0x69f3374C3387fAbcB4d190bB1E616c9639631636",
  },
  contractABIs: {
    Auction: [
      'function setBidder(uint256 _tokenId, uint256 _amount) external',
      'function claimRefund(uint256 _tokenId) external',
      'function cinu() public view returns (address)'
    ],
    ERC20: [
      'function allowance(address owner, address spender) public view returns (uint256)',
      'function approve(address guy, uint wad) public returns (bool)'
    ]
  },
};