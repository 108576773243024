import React, { useState, useEffect } from "react";
import styles from "./TopNavbar.module.css";
import { TbMenu2 } from "react-icons/tb";
import { Mailto } from "../hooks/Mailto";
// import Modal from 'react-modal'
import { Modal } from "react-bootstrap";
// import Staking from '../Staking/Staking'
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ConnectWalletButton from "./ConnectWalletButton";
// import { useAuthenticatedAPI } from '../../hooks/useAuthenticatedAPI'
import useWallet from "../../hooks/useWallet";

export default function TopNavbar({ scrollDown }) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [showVote, setShowVote] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [showSoon, setShowSoon] = useState(false);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const { user } = useWallet();
  // const { api } = useAuthenticatedAPI()

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handleCloseVote = () => {
    setShowVote(false);
  };
  const handleShowVote = () => {
    setShowVote(true);
  };
  const handleCloseToken = () => {
    setShowToken(false);
  };
  const handleShowToken = () => {
    setShowToken(true);
  };
  const handleCloseSoon = (e) => {
    setShowSoon(false);
  };
  const handleShowSoon = () => {
    setShowSoon(true);
  };

  // const handleMenuClick = (ref) => {
  //   scrollDown(ref)
  //   setIsNavExpanded(!isNavExpanded)
  // }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });

    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <nav className={styles.navigation}>
      <Link to="/home">
        <img
          src={require("../../assets/website caller inu/pc/1. Caller Inu logo_smalllll.png")}
          alt="logo"
          className={
            isNavExpanded
              ? `${styles.brandLogo} ${styles.expanded}`
              : styles.brandLogo
          }
        />
      </Link>
      <div
        className={
          isNavExpanded
            ? `${styles.navigationMenu} ${styles.expanded}`
            : styles.navigationMenu
        }
      >
        <div className={styles.links}>
          <HashLink
            to="/home/#introductionSection"
            scroll={(el) => scrollWithOffset(el)}
          >
            ABOUT
          </HashLink>
          <HashLink
            to="/home/#utilitiesSection"
            scroll={(el) => scrollWithOffset(el)}
          >
            UTILITIES
          </HashLink>
          <HashLink
            to={
              isDesktop
                ? "/home/#tokenomicsSection"
                : "/home/#tokenomicsSectionMobile"
            }
            scroll={(el) => scrollWithOffset(el)}
          >
            Tokenomics
          </HashLink>
          <HashLink
            to="/home/#artworkSection"
            scroll={(el) => scrollWithOffset(el)}
          >
            ARTWORK
          </HashLink>
          <HashLink
            to={
              isDesktop
                ? "/home/#gallerySection"
                : "/home/#gallerySectionMobile"
            }
            scroll={(el) => scrollWithOffset(el)}
          >
            GALLERY
          </HashLink>
          <HashLink
            to="/home/#teamSection"
            scroll={(el) => scrollWithOffset(el)}
          >
            the team
          </HashLink>
          <HashLink
            to="/home/#supportedBySection"
            scroll={(el) => scrollWithOffset(el)}
          >
            supported by
          </HashLink>

          <Link
            // to="/daopage"
            onClick={handleShowVote}
          >
            DAO
          </Link>

          {/* {user?.is_conclave ? <Link to="/votepage">vote</Link> : <></>} */}

          <Modal
            show={showVote}
            onHide={handleCloseVote}
            centered
            dialogClassName={styles.daoPopup}
          >
            <img
              src={require("../../assets/comingsoon/loading_page-VOTE.png")}
              alt="vote"
              onClick={handleCloseVote}
            />
          </Modal>

          <Link
            // to="/staking"
            onClick={handleShowToken}
          >
            Staking
          </Link>
          <Modal
            show={showToken}
            onHide={handleCloseToken}
            centered
            dialogClassName={styles.daoPopup}
          >
            <img
              src={require("../../assets/comingsoon/loading_page-TOKEN.png")}
              alt="token"
              onClick={handleCloseToken}
            />
          </Modal>
          <a href="https://callerinu.gitbook.io/" target="_blank">
            Whitepaper
          </a>
        </div>
      </div>
      <div
        className={
          isNavExpanded
            ? `${styles.socialMedia} ${styles.expanded}`
            : styles.socialMedia
        }
      >
        <Mailto
          email="callerinu@gmail.com"
          subject="Hello"
          body="Hello world!"
        />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/CallerInu"
        >
          <img
            src={require("../../assets/website caller inu/pc/1. socials_twitter.png")}
            alt="twitter"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/watch?v=3-bTKVJhM9o&ab_channel=CallerInuOfficial"
        >
          <img
            src={require("../../assets/website caller inu/pc/1. socials_youtube.png")}
            alt="youtube"
          />
        </a>
        <a target="_blank" rel="noreferrer" href="https://t.me/CallerInu">
          <img
            src={require("../../assets/website caller inu/pc/1. socials_telegram.png")}
            alt="telegram"
          />
        </a>
        <Link onClick={handleShowSoon}>
          <ConnectWalletButton></ConnectWalletButton>
          {/* <button onClick={handleShowSoon}>connect wallet</button> */}
        </Link>
        <Modal
          show={showSoon}
          onHide={handleCloseSoon}
          centered
          dialogClassName={styles.daoPopup}
        >
          <img
            src={require("../../assets/comingsoon/loading_page-SOON.png")}
            alt="token"
            onClick={handleCloseSoon}
          />
        </Modal>
      </div>
      <button
        className={styles.hamburger}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <TbMenu2 className={isNavExpanded ? styles.expanded : ""} />
      </button>
    </nav>
  );
}
