import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css"; // TODO: check where it's used but it needs to be removed evenutually because it pollutes the global css
import LandingPage from "./components/LandingPage/LandingPage";
import DaoPage from "./components/DaoPage/DaoPage";
import VotePage from "./components/VotePage/VotePage";
import StartupAnimation from "./components/StartupAnimation/StartupAnimation";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Staking from "./components/Staking/Staking";
import Enigma from "./components/Enigma/Enigma";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<StartupAnimation />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/daopage" element={<DaoPage />} />
        <Route path="/votepage" element={<VotePage />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/enigma" element={<Enigma />} />
        {/* <LandingPage /> */}
        {/* <HomePage /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
