import React, { useState, useEffect } from 'react'
import TopNavbar from '../TopNavbar/TopNavbar'
import styles from './VotePage.module.css'
import { useAuthenticatedAPI } from '../../hooks/useAuthenticatedAPI'
import { BsCheck } from 'react-icons/bs'
import useWallet from '../../hooks/useWallet'
import Timer from '../Timer/Timer'

export default function VotePage() {
  const [proposals, setProposals] = useState([])
  const [period, setPeriod] = useState({ currentPeriod: '', timeLeft: '' })

  const { api } = useAuthenticatedAPI()
  const { user } = useWallet()
  // 0xd236bBcca49eeA2eBAbf1eD6622c4fBD1E652240 conclaver
  // 0xa3123e1D8A7EA78608776cF8b083E68b58FbF4d3 holder
  // 0xF92aAB15Cf45d6b161aC74Ac001a6D30e98C3236 normal user

  useEffect(() => {
    if (user?.is_conclave) {
      const func = async () => {
        setProposals(await api.getProposals())
      }
      func()
    }
  }, [user?.is_conclave, api])

  if (!user?.is_conclave) {
    return (
      <>
        <TopNavbar />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor: 'white',
          }}
        >
          you are not authorized
        </div>
      </>
    )
  }

  return (
    <>
      <TopNavbar />
      <div className={styles.proposals}>
        <Timer period={period} setPeriod={setPeriod} />
        {proposals.map((proposal) => (
          <div className={styles.proposal} key={proposal.id}>
            <h3>{proposal.title}</h3>
            <p>{proposal.description}</p>
            {period.currentPeriod === 'voting' ? (
              <div className={styles.votingBox}>
                <div>
                  <p>{proposal.votes_for} for</p>
                  <button
                    onClick={async () => {
                      await api.castVote({
                        proposalId: proposal.id,
                        vote: 'for',
                      })
                      setProposals(await api.getProposals())
                    }}
                    className={
                      proposal.own_vote === 'for'
                        ? styles.forClicked
                        : undefined
                    }
                  >
                    <BsCheck />
                  </button>
                </div>
                <div>
                  <p>{proposal.votes_against} against</p>
                  <button
                    onClick={async () => {
                      await api.castVote({
                        proposalId: proposal.id,
                        vote: 'against',
                      })
                      setProposals(await api.getProposals())
                    }}
                    className={
                      proposal.own_vote === 'against'
                        ? styles.againstClicked
                        : undefined
                    }
                  >
                    X
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
