export function Mailto({ email, subject, body })
{
    return (
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
            <img
                src={require('../../assets/website caller inu/pc/1. socials_mail.png')}
                alt="mail"
            />
        </a>
    );
}