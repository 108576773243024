import React from "react";
import TelegramLoginButton from "react-telegram-login";

const scrollBarStyle = `
  scrollbar
  scrollbar-thumb-rounded-full scrollbar-thumb-cyan-700 hover:scrollbar-thumb-cyan-600
  scrollbar-track-rounded-full scrollbar-track-zinc-800
`;

function Panel({ children, title, className }) {
  return (
    <div
      className={`flex min-h-0 flex-col overflow-hidden rounded-3xl border-4 border-black bg-zinc-900/60 ${className}`}
    >
      <div className="w-fit bg-gradient-to-r from-blue-800 to-cyan-500 bg-clip-text pt-3 pl-4 font-brand text-xl font-bold text-transparent">
        {title}
      </div>
      <div className={`m-2 mt-1 overflow-auto ${scrollBarStyle}`}>
        {children}
      </div>
    </div>
  );
}

function Checker() {
  const logos = [
    "bsc",
    "dex",
    "dex-view",
    "eth",
    "honey",
    "pan",
    "pink",
    "poo",
    "screener",
    "uniswap",
  ];
  return (
    <div className="flex flex-wrap items-center justify-center gap-4 p-2 pr-4">
      {logos.map((logo) => (
        <img
          className="h-12 w-12 object-contain"
          src={require(`./logos/${logo}_s.png`)}
        ></img>
      ))}
    </div>
  );
}

function Tracker() {
  const channels = [
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
    "OverDose",
    "Gollum's Gems",
  ];

  return (
    <div className="flex flex-col p-2 pr-4">
      {channels.map((channel) => (
        <div className="truncate">{channel}</div>
      ))}
    </div>
  );
}

function Telegram() {
  const messages = Array(10).fill({
    channel: "VenoMCalls",
    search: "BSC",
    message: `
Alright everyone, we are opening the VC for our AMA with Snapy - https://t.me/snapyportal

Pinksale Fairlaunch: https://www.pinksale.finance/launchpad/0xA0F15248e6d1D5CB1dD10CdC50569cbdb3555bFF?chain=BSC000000000

Website: https://snapy.photos/
dApp: https://snapy.photos/app/ 

Make sure to tune in and stay till the end, as we will be giving away BNB ❤️
`,
  });

  const localStorageUserKey = "telegram_user";

  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem(localStorageUserKey))
  );

  function handleLogin(user) {
    setUser(user);
    localStorage.setItem(localStorageUserKey, JSON.stringify(user));
  }

  return (
    <div className="flex flex-col gap-6 p-2 pr-6">
      {user === null ? (
        <div className="flex w-full justify-center pt-2">
          <TelegramLoginButton
            dataOnauth={handleLogin}
            botName="keyword_bot_dev_bot"
          />
        </div>
      ) : (
        messages.map((message) => (
          <div className="flex flex-col gap-1 leading-tight">
            <div>
              <div className="space-x-2">
                <span className="text-lg font-bold tracking-wide">
                  {message.search}
                </span>
                <span className="text-zinc-400">Personalized search</span>
              </div>
              <div className="text-blue-500">{message.channel}</div>
            </div>
            <div className="break-all text-white">{message.message.trim()}</div>
          </div>
        ))
      )}
    </div>
  );
}

export default function TopNavbar() {
  return (
    <div
      className="grid w-full grid-cols-4 gap-4 bg-cover bg-center p-8 text-zinc-100 md:h-screen"
      style={{
        backgroundImage: `url(${require("./enigma_background.png")})`,
      }}
    >
      <div className="grid-col col-span-4 grid gap-4 md:col-span-1 md:min-h-0">
        <div className="flex justify-center">
          <img
            className="h-44 object-contain"
            src={require("./logo.png")}
          ></img>
        </div>
        <Panel title="Tracker" className="h-96 md:h-auto">
          <Tracker />
        </Panel>
        <Panel title="Checker" className="h-48 md:h-auto">
          <Checker />
        </Panel>
      </div>
      <div className="col-span-4 grid grid-cols-2 grid-rows-2 gap-4 md:col-span-3 md:min-h-0">
        <Panel
          title="Telegram"
          className="col-span-2 h-140 md:col-span-1 md:h-auto"
        >
          <Telegram />
        </Panel>
        <Panel
          title="Twitter"
          className="col-span-2 h-140 md:col-span-1 md:h-auto"
        ></Panel>
        <Panel
          title="Live new pairs"
          className="col-span-2 h-140 md:h-auto"
        ></Panel>
      </div>
    </div>
  );
}
