import React, { useState } from 'react'
import { ethers } from 'ethers'
import styles from './Auction.module.css'
import useWallet from '../../hooks/useWallet'
import { useAuthenticatedAPI } from '../../hooks/useAuthenticatedAPI'
import { useEffect } from 'react'

const oneTimeApproveAmount = ethers.BigNumber.from(
  '115792089237316195423570985008687907853269984665640564039457584007913129639935',
)

export default function Auction() {
  const {
    getContract,
    sendContractTransaction,
    currentAddress,
    explorerUrl,
  } = useWallet()
  const { api } = useAuthenticatedAPI()
  const [biddingInfo, setBiddingInfo] = useState({
    bidAmount: 0,
    currentIssue: 5,
    top3Bidders: [],
  })

  const getTop3Bidders = async () => {
    const top3Bidders = await api.getTop3Bidders({
      tokenId: biddingInfo.currentIssue,
    })
    setBiddingInfo({ ...biddingInfo, top3Bidders: top3Bidders })
  }

  useEffect(() => {
    getTop3Bidders()
    const interval = setInterval(() => getTop3Bidders(), 120000)

    return () => clearInterval(interval)
  }, [biddingInfo.currentIssue])

  const aproveTokens = async (amount) => {
    const auction = await getContract('Auction')
    const cinuAddress = await auction.cinu()
    const tokenContract = await getContract('ERC20', cinuAddress)
    const currentlyAllowed = await tokenContract.allowance(
      currentAddress,
      auction.address,
    )
    if (currentlyAllowed.lt(amount)) {
      await sendContractTransaction(
        tokenContract,
        'approve',
        [auction.address, oneTimeApproveAmount],
        'Approve bidding contract to handle tokens',
      )
    }
  }

  const bidAmount = async () => {
    if (biddingInfo.bidAmount > 0) {
      const auction = await getContract('Auction')
      await aproveTokens(biddingInfo.bidAmount)
      await sendContractTransaction(
        auction,
        'setBidder',
        [biddingInfo.currentIssue, biddingInfo.bidAmount],
        'Placing bid!',
      )
      await getTop3Bidders()
    }
  }

  const claimRefund = async () => {
    const auction = await getContract('Auction')
    await sendContractTransaction(
      auction,
      'claimRefund',
      [biddingInfo.currentIssue],
      'Placing bid!',
    )
    await getTop3Bidders()
  }

  const onBidAmountChanged = (e) => {
    setBiddingInfo({ ...biddingInfo, bidAmount: e.target.value })
  }

  return (
    <>
      <div className={styles.auction}>
        <img
          src={require('../../assets/auction/auction_w.png')}
          alt="auction"
          className={styles.auctionImage}
        />

        <img
          src={require(`../../assets/auction/numbered_nft/${biddingInfo.currentIssue}.png`)}
          alt="nft"
          className={styles.nftImage}
        />
        <h5 className={styles.issues_mobile}></h5>
        {[...Array(4)].map((_, idx) => (
          <div className={styles['issueRow' + (idx + 1)]} key={idx}>
            <div>
              {[...Array(5)].map((_, num) => (
                <div
                  className={styles.issueDiv}
                  key={5 * idx + num + 1}
                  onClick={() => {
                    setBiddingInfo({
                      ...biddingInfo,
                      currentIssue: 5 * idx + num + 1,
                    })
                  }}
                >
                  <p>ISSUE</p>
                  <p>No.{5 * idx + num + 1}</p>
                  <p className={styles.issueNum_mobile}>{5 * idx + num + 1}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
        <form>
          <input
            className={styles.auctionInput}
            type="number"
            onChange={onBidAmountChanged}
          />
          <img
            src={require('../../assets/auction/bid.png')}
            alt="bid"
            className={styles.bidBtn}
            onClick={bidAmount}
          />
          <img
            src={require('../../assets/auction/claim.png')}
            alt="claim"
            className={styles.claimBtn}
            onClick={claimRefund}
          />
        </form>
      </div>
      <div className={styles.bidsBoard}>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Amount</th>
              <th>Wallet</th>
              <th>Date</th>
              <th>Verify</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(3)].map((_, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  {biddingInfo.top3Bidders.length > idx ? (
                    <>
                      <td>{biddingInfo.top3Bidders[idx].amount}</td>
                      <td>
                        {biddingInfo.top3Bidders[idx].bidder.slice(0, 4) +
                          '...' +
                          biddingInfo.top3Bidders[idx].bidder.slice(-3)}
                      </td>
                      <td>
                        {new Date(
                          biddingInfo.top3Bidders[idx].bidded_at,
                        ).toLocaleString()}
                      </td>
                      <td>
                        <a
                          href={
                            explorerUrl + biddingInfo.top3Bidders[idx].tx_hash
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require('../../assets/auction/etherscanlogo.png')}
                          />
                        </a>
                      </td>
                    </>
                  ) : (
                    <>
                      <td></td> <td></td> <td></td> <td></td>
                    </>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
