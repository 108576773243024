export class API {
  constructor(globalParams) {
    this._uri = `${window.location.protocol}//${window.location.hostname}:8085/`;
    this._globalParams = globalParams;

    return new Proxy(this, {
      get: (object, key, value, proxy) => {
        return async (params) => {
          const response = await fetch(this._uri, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              method: key,
              params: {
                ...this._globalParams,
                ...params,
              },
            }),
          });

          const responseObject = await response.json();

          if (responseObject.error) {
            throw responseObject.error;
          } else if (responseObject.result) {
            return responseObject.result;
          } else {
            throw new Error("Invalid response");
          }
        };
      },
    });
  }
};