import React from 'react'
import styles from './Staking.module.css'

import walletIntegration from '../WalletIntegration'
import { ethers } from 'ethers'
import { useState, useEffect, useRef } from 'react'
import TopNavbar from '../TopNavbar/TopNavbar'

const config = {
  contractAddress: '0xbd592aFd5Bc2927d83efD1dc96eAd650177677A1',
  contractABI: [
    'function token() public view returns (address)',
    'function stake(uint256 amount) external',
    'function unstake() external',
    'function userUnstakeBalance(address _user) public view returns (uint256)',
    'function staked(address _target) public view returns (uint256)',
  ],
  tokenContractAddress: '0x5B52C3313a3269A69fFaC0c4fee4edD4E4878268',
  tokenContractABI: [
    'function allowance(address owner, address spender) public view returns (uint256)',
    'function approve(address spender, uint256 amount) public returns (bool) ',
    'function balanceOf(address owner) public view returns(uint256)',
  ],
}

export default function Staking() {
  const [staked, setStaked] = useState(0)
  const [toUnstake, setToUnstake] = useState(0)
  const [modalMessage, setModalMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const stakeVideo = useRef(null)
  const unstakeVideo = useRef(null)

  async function isApprovedForAll(amount) {
    const targetContract = new ethers.Contract(
      config.tokenContractAddress,
      config.tokenContractABI,
      new ethers.providers.Web3Provider(window.ethereum),
    )
    const stakeContract = new ethers.Contract(
      config.contractAddress,
      config.contractABI,
      new ethers.providers.Web3Provider(window.ethereum),
    )
    let allowance = await targetContract.allowance(
      window.ethereum.selectedAddress,
      config.contractAddress,
    )
    let balance = await targetContract.balanceOf(
      window.ethereum.selectedAddress,
    )
    let approved = allowance.gte(amount)
    if (!approved) {
      let data = [config.contractAddress, balance]
      approved = await walletIntegration.sendTransaction(
        data,
        'approve',
        config.tokenContractABI,
        await stakeContract.token(),
        'Please unlock tokens for staking on metamask!',
        { setModalMessage, handleShow, handleClose, setIsLoading },
      )
    }
    return approved
  }

  async function stake() {
    stakeVideo.current.play()
    const targetContract = new ethers.Contract(
      config.tokenContractAddress,
      config.tokenContractABI,
      new ethers.providers.Web3Provider(window.ethereum),
    )
    const stakeContract = new ethers.Contract(
      config.contractAddress,
      config.contractABI,
      new ethers.providers.Web3Provider(window.ethereum),
    )
    if (
      await walletIntegration.verifyWalletConnection({
        handleShow,
        setModalMessage,
      })
    ) {
      let amount = await targetContract.balanceOf(
        window.ethereum.selectedAddress,
      )
      if (amount.gt(0.0)) {
        if (await isApprovedForAll(amount)) {
          let data = [amount]
          await walletIntegration.sendTransaction(
            data,
            'stake',
            config.contractABI,
            config.contractAddress,
            'Staking',
            { setModalMessage, handleShow, handleClose, setIsLoading },
          )
          await setStakeInfo(stakeContract)
          setStaked(amount.toString())
          setToUnstake(amount.toString())
          //ethers.utils.formatEther
        }
      } else {
        // alert('Nothing to stake')
        setModalMessage(() => 'Nothing to stake')
        handleShow()
      }
    }
  }

  async function unstake() {
    unstakeVideo.current.play()
    if (
      await walletIntegration.verifyWalletConnection({
        handleShow,
        setModalMessage,
      })
    ) {
      handleShow()
      await walletIntegration.sendTransaction(
        [],
        'unstake',
        config.contractABI,
        config.contractAddress,
        'Unstaking',
        { setModalMessage, handleShow, handleClose, setIsLoading },
      )
      await setStakeInfo()
    }
  }

  async function setStakeInfo(stakeContract) {
    try {
      stakeContract =
        stakeContract ||
        new ethers.Contract(
          config.contractAddress,
          config.contractABI,
          new ethers.providers.Web3Provider(window.ethereum),
        )
      setStaked(
        (
          await stakeContract.staked(window.ethereum.selectedAddress)
        ).toString(),
      )
      setToUnstake(
        (
          await stakeContract.userUnstakeBalance(
            window.ethereum.selectedAddress,
          )
        ).toString(),
      )
    } catch (error) {
      console.log('code', error.code)
      setModalMessage(error)
      handleShow()
    }
  }

  useEffect(() => {
    walletIntegration.initialize()
    async function updateStakeInfo() {
      if (
        await walletIntegration.verifyWalletConnection({
          handleShow,
          setModalMessage,
        })
      ) {
        const stakeContract = new ethers.Contract(
          config.contractAddress,
          config.contractABI,
          new ethers.providers.Web3Provider(window.ethereum),
        )
        await setStakeInfo(stakeContract)
      }
    }
    updateStakeInfo()
    const interval = setInterval(() => {
      updateStakeInfo()
    }, 5000)

    return () => clearInterval(interval)
  })

  const stopProp = (e) => {
    e.stopPropagation()
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const Modal = (
    <div
      className={show ? styles.stakingModal : styles.modalClose}
      onClick={(e) => stopProp(e)}
    >
      <button onClick={handleClose}>X</button>
      <div className={styles.modalContent}>
        <p>{modalMessage}</p>
        {isLoading ? <div className={styles.spinner}></div> : <></>}
      </div>
    </div>
  )

  return (
    <>
      <TopNavbar />
      <div className={styles.content}>
        <div className={styles.stakeTitle}>
          <img
            src={require('../../assets/staking/Caller-Farm-title&boxes.png')}
          ></img>
          <div className={styles.toBeStakedBox}>{staked}</div>
          <div className={styles.toBeUnstakedBox}>{toUnstake}</div>
        </div>
        <div className={styles.stakingButtons}>
          <div className={styles.leftButtonHolder} onClick={stake}>
            <video ref={stakeVideo} muted playsInline>
              {/* <source src={require('../../assets/staking/left-pig_cropped-coinin.mov')} type='video/quicktime' codecs="hevc"/> */}
              <source
                src={require('../../assets/staking/Staking-Left-Pig-CoinIn.webm')}
                type="video/webm"
              />
            </video>
          </div>
          <div className={styles.rightButtonHolder} onClick={unstake}>
            <video ref={unstakeVideo} muted playsInline>
              {/* <source src={require('../../assets/staking/right_pig_cropped_-_coin_out__H.265.mp4')+'#t=0.1'} type='video/mp4' codecs="hevc"/> */}
              <source
                src={require('../../assets/staking/Staking-Right-Pig-CoinOut.webm')}
                type="video/webm"
              />
            </video>
          </div>
        </div>
        {Modal}
      </div>
    </>
  )
}
