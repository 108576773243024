import { ethers } from "ethers";

const config = {
  networkName: "Polygon Mainnet",
  explorerUrl: "https://polygonscan.com/tx/",
  openSeaUrl: "https://opensea.io/account",
  networkParams: {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Polygon Mainnet",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mainnet.matic.network"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
}

function escapeHtml(unsafe)
{
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

async function sendTransaction(data, transactionFuncName, contractABI, contractAddress, message, { setModalMessage, handleShow, handleClose, setIsLoading } = {})
{

  // alert(message);
  setModalMessage(() => (message))
  handleShow()

  if (!(await verifyWalletConnection()))
  {
    // return alert('Error with MetaMask. Please refresh and try again.');
    return setModalMessage(() => ('Error with MetaMask. Please refresh and try again.'));
  }

  const iface = new ethers.utils.Interface(contractABI);
  const params = iface.encodeFunctionData(transactionFuncName, data);
  try
  {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [{
        from: window.ethereum.selectedAddress,
        to: contractAddress,
        value: "0x0",
        data: params
      },],
    });
    // alert(`Transaction submitted. Please wait for confirmation.`); //MESAGE MODAL WITH LOADING
    setModalMessage(() => ('Transaction submitted. Please wait for confirmation.')); //MESAGE MODAL WITH LOADING
    setIsLoading(() => true)
    const tx = await (new ethers.providers.Web3Provider(window.ethereum)).getTransaction(txHash);
    const txReceipt = await tx.wait();
    // alert(`Done`); // CLOSE THE MESSAG MODAL INTEAD
    setIsLoading(() => false)
    handleClose()
    return true;
  } catch (err)
  {
    if (err.code == -32603 || err.code == 4001)
    {
      // return alert("User rejected the transaction");
      setModalMessage(() => ("User rejected the transaction"))
      setIsLoading(() => false)
      setTimeout(() =>
      {
        handleClose()
      }, 2000)
    } else
    {
      // return alert(`Error with Transaction. Please refresh and try again! Error: <p style="font-size:12px; word-wrap: break-word;"> ${escapeHtml(err.stack)}</p>`);
      setModalMessage(() => (`Error with Transaction. Please refresh and try again! Error: <p style="font-size:12px; word-wrap: break-word;"> ${escapeHtml(err.stack)}</p>`));
      // setModalMessage(() => (err))
      handleShow()
      setIsLoading(() => false)
    }
  }
}

async function verifyWalletConnection({ noAlert, handleShow, setModalMessage } = {})
{
  if (!window.ethereum)
  {
    // noAlert || alertUser('Please install MetaMask to interact with this feature');
    noAlert || setModalMessage(() => ('Please install MetaMask to interact with this feature'))
    handleShow()
  }

  if (!window.ethereum.selectedAddress && noAlert && localStorage.getItem('verifyWalletRequested') === '1')
  {
    return;
  }

  // localStorage.setItem('verifyWalletRequested', '1');
  let accounts;
  try
  {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: config.networkParams.chainId }], // chainId must be in hexadecimal numbers
    });
    accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    if (window.ethereum.chainId != config.networkParams.chainId)
    {
      // noAlert || alertUser(`Please switch MetaMask network to ${config.networkName}`);
      // return;
      noAlert || setModalMessage(() => (`Please switch MetaMask network to ${config.networkName}`))
      handleShow()
    }
  } catch (error)
  {
    if (error.code == -32002)
    {
      // noAlert || alertUser('Please open your MetaMask and select an account');
      // return;
      noAlert || setModalMessage(() => ('Please open your MetaMask and select an account'))
      handleShow()
    } else if (error.code == 4001)
    {
      // noAlert || alertUser('Please connect with MetaMask');
      // return;
      noAlert || setModalMessage(() => ('Please connect with MetaMask'))
      handleShow()
    } else
    {
      // throw error;
      setModalMessage(() => (error))
      handleShow()
    }
  }

  return accounts[0];
}


export default {
  initialize()
  {
    verifyWalletConnection();
  },
  sendTransaction,
  verifyWalletConnection
}