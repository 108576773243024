import { useMemo } from "react";
import { API } from "./API";
import useWallet from "./useWallet";

export const useAuthenticatedAPI = (options = {}) => {
  const { currentAddress, signature, signatureData } = useWallet();

  return useMemo(
    () => ({ api: new API({ currentAddress, signature, signatureData }) }),
    [currentAddress, signature, signatureData]
  );
};